import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { AsyncStorage } from '../AsyncStorage';

import { fallback, defaultNamespace, namespaces } from './config';
import date from './date';
import languageDetector from './languageDetector';
import { translationLoader } from './translationLoader';

export const i18n = {
  /**
   * @returns {Promise}
   */
  init: () =>
    new Promise((resolve, reject) => {
      i18next
        .use(initReactI18next)
        .use(languageDetector)
        .use(translationLoader)
        .init(
          {
            fallbackLng: fallback,
            ns: namespaces,
            defaultNS: defaultNamespace,
            // Explicitly use the "old way" of formatting our JSON files
            // See https://www.i18next.com/misc/migration-guide for more info
            compatibilityJSON: 'v3',
            interpolation: {
              escapeValue: false,
              format(value, format) {
                if (value instanceof Date) {
                  return date.format(value, format);
                }
              },
            },
          },
          async (error) => {
            if (error) {
              return reject(error);
            }
            const language = i18next.language;

            // Check if language is set in AsyncStorage, if not, update it
            try {
              let storedLanguage = await AsyncStorage.getItem('language');
              if (storedLanguage === 'se') {
                storedLanguage = 'sv';
              }
              if (!storedLanguage) {
                await AsyncStorage.setItem('language', language);
              }
            } catch (e) {
              // Ignore this error as it's not crucial for app functiality
            }
            date.init(language).then(resolve);
          }
        );
    }),

  /**
   * @param {string} key
   * @param {Object} options
   * @returns {string}
   */
  t: (key, options) => i18next.t(key, options),

  /**
   * @returns {string}
   */
  get locale() {
    return i18next.language;
  },

  /**
   * @returns {'LTR' | 'RTL'}
   */
  get dir() {
    return i18next.dir().toUpperCase();
  },

  /**
   * @returns {boolean}
   */
  get isRTL() {
    return true;
  },

  changeLanguage: (lng) => {
    date.init(lng);
    i18next.changeLanguage(lng);
  },

  /**
   * Similar to React Native's Platform.select(),
   * i18n.select() takes a map with two keys, 'rtl'
   * and 'ltr'. It then returns the value referenced
   * by either of the keys, given the current
   * locale's direction.
   *
   * @param {Object<string,mixed>} map
   * @returns {mixed}
   */
  select(map) {
    const key = this.isRTL ? 'rtl' : 'ltr';

    return map[key];
  },
};

export const { t } = i18n;
