import React from 'react';

import { useTodoListSections, useResource } from '@formue-app/core';

import { MenuPage } from './base/MenuPage';
import { TodoList } from '../components/todo/TodoList';

export const TodoPage = (props) => {
  const loading = useResource([
    'TASKS/INDEX',
    'EXPERT_INPUTS/INDEX',
    // We _don't_ want the loading indicator to show when fetching a single
    // signing event (SIGNING_EVENTS/SHOW) while we poll for updates
    'SIGNING_EVENTS/INDEX',
  ]);
  const sections = useTodoListSections();

  return (
    <MenuPage {...props}>
      <TodoList columns={12} sections={sections} loading={loading} />
    </MenuPage>
  );
};
